import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionService } from '../services/authentication/session.service';
import { environment } from '@environments/environment.prod';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) {}

  log(message: string): void {
    console.log('SESSION_INTERCEPTOR / ' + message);
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const user = this.sessionService.userDetails;
    const officer = this.sessionService.officerDetails;
    const isAuthenticated = officer && officer.token;
    const isApiUrl = request.url.startsWith(environment.apiRootUrl);
    if (request.url.startsWith(`${environment.apiRootUrl}/Auth/Login`)) {
      // this.log('login');
    } else if (
      request.url.startsWith(`${environment.apiRootUrl}/Auth/Reset-Password`)
    ) {
      // this.log('reset password');
    } else if (isAuthenticated && isApiUrl && officer) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${officer.token}` },
      });
      // this.log('authenticated');
      // this.log(officer.token);
    } else {
      // this.log('anonymous');
    }
    return next.handle(request);
  }
}
