import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  LOCALE_ID,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  DEFAULT_CURRENCY_CODE,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SessionErrorInterceptor } from './interceptor/session-error.interceptor';
import { SessionInterceptor } from './interceptor/session.interceptor';
import { SessionService } from './services/authentication/session.service';
import { ConfigurationService } from './services/cm/configuration.service';
import { WINDOW_PROVIDERS } from './services/cm/window.provider';

import '@angular/common/locales/global/fr';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { paginationPersonnalise } from './paginationPersonnalise';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    SessionService,
    ConfigurationService,
    // {
    //   provide: APP_INITIALIZER,
    //   // useFactory: appInitializerConfiguration,
    //   deps: [ConfigurationService, SessionService],
    //   multi: true,
    // },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionErrorInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: paginationPersonnalise },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
//openID
//SAML2
export function appInitializerConfiguration(
  cm: ConfigurationService,
  session: SessionService
): void {
  session.checkAuthenticatedUser();
  // return cm.appInitializer();
}
