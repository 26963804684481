export const environment = {
  production: true,
  // apiRootUrl: '/api/2.0',
  apiRootUrl: '/api/0.1',
  // apiConfigurationUrl: '/api/2.0/Configuration',
  // apiCommonUrl: '/api/2.0',
  // apiAuthenticationUrl: '/api/2.0',
  // apiDashboardUrl: '/api/2.0',
  // apiLibFiles: '/api/2.0',
  // apiLieFiles: '/api/2.0/LieFiles',
  // apiDraftUrl: '/api/2.0',
  // apiDocumentUrl: '/api/2.0',
  apiPaymentOption: '/api/0.1/Procuro/payment-option',
  apiLieFilesList: '/api/0.1/LieFiles',
  apiLieFileByReference: '/api/0.1/LieFiles',
  apiLibFilesList: '/api/0.1/LibFiles',
  apiGetTaxCenters: '/api/0.1/Admin/sfpe',
  apiPutTaxCenters: '/api/0.1/Admin/sfpe',
  apiCerfas: '/api/0.1/Cerfas',
  apiPostCerfa: '/api/0.1/Cerfas',
  apiLoadCerfa: '/api/0.1/Procuro/create-cerfa',
  apiRegisterOfficer: '/api/0.1/Auth/register',
  apiAuthActivate: '/api/0.1/Auth/activate',
  apiAuthenticationUrl: '/api/0.1/Auth/login',

  //
  apiAdminSfpe: '/api/0.1/Admin/sfpe',
  apiAdmin: 'api/0.1/Admin',
  apiAuth: 'api/0.1/Auth',
};
