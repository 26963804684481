import { HttpErrorResponse } from "@angular/common/http";

// future use
export enum ApplicationErrorStatus {
  InvalidLogin = 'InvalidLogin',
  AlreadyExist = 'AlreadyExist',
  NotFound = 'NotFound',
  Warning = 'Warning',
  Error = 'Error',
  FatalWarning = 'FatalWarning',
  FatalError = 'FatalError',
}

export class ApplicationError extends Error {
  status!: ApplicationErrorStatus;
  //message!: string;
  httpError!: HttpErrorResponse | null;
}

//@todo class for financial data amounts management
export class Amount {
  private _amount: number;
  //@todo get set methods
  //@todo convert in decimal or not
  //@todo complete with pipe for presentation
  //@todo detect locale for conversion
}


/*
  Qualification de la relation au bénéficiaire dans la clause du contrat
  Données stockées : {contract, bénéficiary}
  */
export enum ClauseRelationship
{
    FAMILY = 1,
    NOMINATIVE = 2,
}

// Depending on Draft
export enum LibFileStatus {
  Locked = 'locked',
  Enabled = 'enabled',
  Initialized = 'initialized',
}

//
export enum LieFileStatus {
  PartiallyInitialized = 'partiallyinitialized',
  Writing = 'writing',
  Reviewing = 'reviewing',
  Initialized = 'initialized',
  Closed = 'closed',
  Validated = 'validated',
}

//
//
// keystring name, groupstep
//
export interface GroupStep {
  // Automate progression
  groupId: string;
  stepId: string;
  // Component for action
  componentId: string;  //stepptr01..06
  componentActionId: string;  //string name to do something in the stepprtNN
  // left side bar flags and tags
  fContractsSummary: string | null;
  fDocumentsSummary: string | null;
  fInlineInformations: string | null;
  //
  events: {
    event: string;
    nextStep: string;
    //updatedocumentlist: groupname
    //saveWorkflowFile: y|n
    //validatecurrent group
    function: {};
  }[];
}

export enum GroupStatus {
  Inhibited = 'Inhibited', // Issue #10 AXA/specific : Inhibited => means that the step is totally hidden
  Deactivated = 'Deactivated',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Done = 'Done',
  DoneWithWarnings = 'DoneWithWarnings',
  DoneWithErrors = 'DoneWithErrors'
}

export enum GroupAction {
  group01 = 'group01', // activation du dossier
  group02 = 'group02',
  group03 = 'group03',
  group04 = 'group04',
  group05 = 'group05',
  group06 = 'group06'
}

// @warning keep this enum as number /!\
export enum WorkflowType {
  isUndefined = 0,
  isTaxExemption = 1,
  isSpouse = 2, // Exo conjoint - deprecated
  isTepa2007 = 2, // Exo conjoint
  is757b = 3, // 757b
  is990i = 4 // 990i
}

export enum ExitReason {
  refuseBenefits = 'refuseBnenefits',
  invalidRelationShip = 'invalidRelationShip',
  signature = 'signature',
  normal = 'normal',
  eos = 'eos',
  eof = 'eof'
}

export enum UserType {
  beneficiary = 1,
  insured = 2,
  drafts = 3,
  viewLibFile = 4
}

