import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';

import { SessionService } from '../services/authentication/session.service';

@Injectable()
export class SessionErrorInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // pentest !
        if ([401, 403].includes(err.status) && this.sessionService.userDetails) {
          this.sessionService.logout();
        }
        // @todo 5xx => FatalError
        // login 400 => Just a managment exception error for login
        // @todo NotFound
        // @todo AlreadyExist
        // console.log(JSON.stringify(err));
        // const error = (err && err.error && err.error.message) || err.statusText;
        // console.error(err);
        return throwError(err);
      }));
  }
}
