import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, map, Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import { Group } from './role.enum';

import { User } from '../../models/user.model';
import {
  ApplicationError,
  ApplicationErrorStatus,
} from '@app/modules/shared/models/common.model';
import { Officer } from '@class/officer.model';
import { environment } from '@environments/environment.prod';
import { ApiResponseInterface } from '@app/interceptor/session-response.interface';
import { SessionRequestInterface } from '@app/interceptor/session-request.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private officer: Officer = new Officer();
  private _authenticatedOfficer: BehaviorSubject<Officer> =
    new BehaviorSubject<Officer>(new Officer());
  logger: boolean = false;
  public user!: Observable<User>;

  public options: {
    consoleLogging: boolean;
  } = {
    consoleLogging: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) {
    // this._authenticatedUser.next(new User(null, null));
    this._authenticatedOfficer.next(new Officer());
  }

  log(message: string, ...args: any[]): void {
    if (args.length && this.logger) {
      console.log('SERVICES/SESSIONSERVICE/' + message, ...args);
    } else {
      console.log('SERVICES/SESSIONSERVICE/' + message);
    }
  }

  isAuthenticated(): boolean {
    return (
      this.officerDetails !== null &&
      this.officerDetails.guid !== '' &&
      this.officer.token !== ''
    );
  }

  authenticatedUser(): BehaviorSubject<Officer> {
    return this._authenticatedOfficer;
  }

  reloadAuthenticatedUser(): void {
    this._authenticatedOfficer.next(this.officerDetails as Officer);
  }

  checkAuthenticatedUser(): void {
    this.log('check user');
    if (this.isAuthenticated()) {
      this.reloadAuthenticatedUser();
    }
  }
  isLidixAdministrator(): User | null {
    let user = this.userDetails;
    return user;
  }
  //@todo change this control
  isGroup(group: Group): boolean {
    if (this.officerDetails && this.isAuthenticated()) {
      if (this.officerDetails.group === group) {
        return true;
      }
    }
    return false;
  }

  public set userDetails(value: User | null) {
    localStorage.setItem('sessionUser', JSON.stringify(value));
  }
  public get userDetails(): User | null {
    const userDetails = localStorage.getItem('sessionUser');
    if (userDetails) {
      return JSON.parse(userDetails) as User;
    }
    return null;
  }

  public set officerDetails(value: Officer | null) {
    localStorage.setItem('sessionOfficer', JSON.stringify(value));
  }
  public get officerDetails(): Officer | null {
    const userDetails = localStorage.getItem('sessionOfficer');
    if (userDetails) {
      return JSON.parse(userDetails) as Officer;
    }
    return null;
  }
  login(officer: Officer): Observable<Officer> {
    this.officer.group = Group.Officer;
    const ObUser = new Observable<Officer>((observer) => {
      const sUri = `${environment.apiAuthenticationUrl}/${officer.taxCenter.hashedReference}`;
      const mLoginRequest = {
        code: 0,
        data: { username: officer.username, password: officer.password },
      } as SessionRequestInterface;
      this.httpClient
        .post<ApiResponseInterface>(sUri, mLoginRequest)
        .subscribe({
          next: (mUser) => {
            this.officerDetails = Officer.deserializeLoginForm(mUser.data);
            this.officerDetails = Object.assign(this.officerDetails, officer);
            observer.next(this.officerDetails);
            this._authenticatedOfficer.next(this.officerDetails);
          },
          error: (err: HttpErrorResponse) => {
            let evtError = {};
            if (err.status === 400) {
              evtError = {
                status: ApplicationErrorStatus.InvalidLogin,
                message:
                  "Votre identifiant et/ou votre mot de passe n'est pas valide",
              } as ApplicationError;
            } else {
              evtError = {
                status: ApplicationErrorStatus.FatalError,
                message:
                  'Une erreur système est survenue, Veuillez ressayer ultérieurement.',
              } as ApplicationError;
            }
            observer.error(evtError);
          },
        });
    });
    return ObUser;
  }

  logout(): void {
    // this._authenticatedUser.next(new User(null, null));
    this._authenticatedOfficer.next(new Officer());

    //localStorage.clear(); //@caution because stash liefiles : waiting for a better solution
    localStorage.removeItem('sessionOfficer');

    sessionStorage.clear();

    this.router
      .navigate(['/account/login'], { relativeTo: this.route })
      .then(() => {
        window.location.reload();
      });
  }
}
