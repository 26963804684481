import { Component } from '@angular/core';
import { ConfigurationService } from './services/cm/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title: string = 'p757b-bo';
  constructor(public cm: ConfigurationService) {}
}
