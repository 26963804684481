import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ConfigurationService } from '@app/services/cm/configuration.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public cm: ConfigurationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.cm.isLoading.next(true);
    return next
      .handle(request)
      .pipe(finalize(() => this.cm.isLoading.next(false)));
  }
}
