import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './window.provider';
import { SessionService } from '../authentication/session.service';
import { Group } from '../authentication/role.enum';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject } from 'rxjs';

import {
  RelationShip,
  Relationship,
} from '@app/modules/shared/models/relationship.model';
import { Country } from '@app/modules/shared/models/country.model';
import { Product } from '@app/modules/shared/models/product.model';
import { Officer } from '@class/officer.model';

export enum APPCUSTOMERSIDS {
  UNKNOWN = 'unknown',
  LIFE = 'life',
  AXADGFIP = 'axa',
  SURAVENIR = 'suravenir',
  BNP = 'bnp',
  PREVOIR = 'prevoir',
  CONSERVATEUR = 'conservateur',
}
export enum LINKTYPES {
  GDPR = 'gdpr',
  LOGO = 'logo',
  SUPPORT = 'support',
  GLOBALCSS = 'globalcss',
  customerName = 'customerName',
  title = 'title',
}
export enum APPPARAMETERS {
  DigitalSignatureProvider = 'fakesign',
  CommercialContact = 'CommercialContact', //on|off
  customerName = 'customerName',
  customerRights = 'customerRights',
  financialMode = 'financialMode', //full | partial
  civilStatusMode = 'civilStatusMode', //full | partial
  stashLieFile = 'stashLieFile', //yes | no
  productDescription = 'productDescription', //yes | no
  title = 'title',
  registerCheckEmail = 'strict', // strict | ifexists | none
  actionReminders = 'actionReminders',
  actionActivation = 'actionActivation',
}

//
export interface UxuiOptGroup {
  letter: string;
  references: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  protected __personalizations: BehaviorSubject<Map<LINKTYPES, string>> =
    new BehaviorSubject<Map<LINKTYPES, string>>(
      new Map([
        [LINKTYPES.customerName, 'Unknown'],
        [LINKTYPES.title, 'Accueil gestionnaire'],
      ])
    );
  logger: boolean = false;
  protected __parameters: BehaviorSubject<Map<APPPARAMETERS, string>> =
    new BehaviorSubject<Map<APPPARAMETERS, string>>(
      new Map([[APPPARAMETERS.DigitalSignatureProvider, 'fakesign']])
    );

  protected _contextOfficer: ReplaySubject<Officer> =
    new ReplaySubject<Officer>(1);
  protected _userOfficer: ReplaySubject<Officer> = new ReplaySubject<Officer>(
    1
  );
  protected _contextOfficers: BehaviorSubject<Officer[]> = new BehaviorSubject<
    Officer[]
  >([new Officer()]);

  protected _obsRelationships: BehaviorSubject<Relationship[]> =
    new BehaviorSubject<Relationship[]>([]);
  protected _Relationships: Relationship[] = [];

  protected _obsCountries: BehaviorSubject<Country[]> = new BehaviorSubject<
    Country[]
  >([]);
  protected _Countries: Country[] = [];
  protected _mapCountries: Map<string, Country> = new Map([]);

  protected _obsProducts: BehaviorSubject<Product[]> = new BehaviorSubject<
    Product[]
  >([]);
  protected _subProducts: any;
  protected _Products: Product[] = [];
  protected _mapProductsByName: Map<string, Product> = new Map([]);
  protected _mapProductsById: Map<number, Product> = new Map([]);
  protected _optGroupProducts: UxuiOptGroup[] = []; //only for display in screens

  protected _obsRelationShips: BehaviorSubject<RelationShip[]> =
    new BehaviorSubject<RelationShip[]>([]);
  protected _RelationShips: RelationShip[] = [];

  private _isDemonstration: boolean = false;
  private _customerName: string;
  private _customerId: APPCUSTOMERSIDS;

  public isDevelopment: boolean = false;

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isProduction: boolean = false;
  public isSandbox: boolean = false;
  public isStaging: boolean = false;

  //
  public options: {
    consoleLogging: boolean;
    displayFirstLast: number;
    onlyOneFirstName: boolean;
    productDescription: string;
  } = {
    consoleLogging: true,
    displayFirstLast: 1,
    onlyOneFirstName: true,
    productDescription: '0',
  };

  private subsAuthenticatedUser: any;

  //@todo define a parameters table to set variable in development environment
  //@todo too much specialized/specific have to be abstract
  private _isDraftAuthorize: boolean = false;

  private _links = new Map([
    [LINKTYPES.SUPPORT, '/home/support'],
    [LINKTYPES.GDPR, '/home/gdpr'],
    [LINKTYPES.LOGO, '/assets/skins/unknow/logo.png'],
    [LINKTYPES.GLOBALCSS, '/assets/skins/unknown/styles.css'],
  ]);
  private _personalizations = new Map([
    [LINKTYPES.customerName, 'unknown'],
    [LINKTYPES.title, 'Accueil'],
    [LINKTYPES.SUPPORT, '/home/support'],
    [LINKTYPES.GDPR, '/home/gdpr'],
    [LINKTYPES.LOGO, '/assets/skins/unknow/logo.png'],
    [LINKTYPES.GLOBALCSS, '/assets/skins/unknown/styles.css'],
  ]);
  private _parameters = new Map([
    [APPPARAMETERS.customerName, 'Inconnu'],
    [APPPARAMETERS.customerRights, 'NONE'],
    [APPPARAMETERS.financialMode, 'full'],
    [APPPARAMETERS.civilStatusMode, 'full'],
    [APPPARAMETERS.stashLieFile, 'no'],
    [APPPARAMETERS.CommercialContact, 'on'],
    [APPPARAMETERS.productDescription, '0'],
    [APPPARAMETERS.registerCheckEmail, 'strict'],
  ]);

  constructor(
    @Inject(WINDOW) private window: Window,
    private sessionService: SessionService,
    private http: HttpClient
  ) {
    this.options.consoleLogging = this.consoleLogging(
      ConfigurationService.name
    );
    this.log('configurationService');
    this._isDraftAuthorize = this.sessionService.isGroup(Group.OfficerDraft);

    //@deprecated : change this by Maps
    this._isDemonstration = false;
    const hn: string = this.getHostname();

    //Environment flag
    //@deprecated : get from parameters
    if (hn.includes('.dev.')) {
      this.isDevelopment = true;
    } else if (hn.includes('.qua.')) {
      this.isStaging = true;
    } else if (hn.includes('.prod.')) {
      this.isProduction = true;
    } else if (hn.includes('.local') || hn.includes('localhost')) {
      this.isDevelopment = true;
    } else {
      this.isSandbox = true;
    }

    this.loadParameters();
    this.subsAuthenticatedUser = this.sessionService
      .authenticatedUser()
      .subscribe((user) => {
        if (!(user.guid === 'anonymous@unknown.org')) {
          this.log('authenticatedUser ', user);
        }
      });
  }

  log(message: string, ...args: any[]): void {
    if (this.logger) {
      if (args.length) {
        console.log('ConfigurationService/' + message, ...args);
      } else {
        console.log('ConfigurationService/' + message);
      }
    }
  }

  appInitializer(): void {
    // this.loadParameters();
    // this.log('appInitializer');
    // return () =>
    //   this.http.get(`${environment.apiConfigurationUrl}/parameters`).pipe(
    //     map((mResponseHTTP: any) => {
    //       let lParameters = mResponseHTTP.data;
    //       this.log('parameters', lParameters);
    //       this._customerId = lParameters.customerId;
    //       this.log('this._customersId');
    //       return new Map([
    //         [
    //           APPPARAMETERS.DigitalSignatureProvider,
    //           lParameters.digitalSignatureProvider,
    //         ],
    //         [APPPARAMETERS.CommercialContact, lParameters.commercialContact],
    //         [APPPARAMETERS.customerRights, lParameters.customerRights],
    //         [APPPARAMETERS.financialMode, lParameters.financialMode],
    //         [APPPARAMETERS.civilStatusMode, lParameters.civilStatusMode],
    //         [APPPARAMETERS.stashLieFile, lParameters.stashLieFile],
    //         [APPPARAMETERS.productDescription, lParameters.productDescription],
    //         [APPPARAMETERS.registerCheckEmail, lParameters.registerCheckEmail],
    //         [APPPARAMETERS.actionReminders, lParameters.actionReminders],
    //         [APPPARAMETERS.actionActivation, lParameters.actionActivation],
    //       ]);
    //     }),
    //     map((parametersMap) => {
    //       this.__parameters.next(parametersMap);
    //       this._parameters = parametersMap;
    //       this.options.productDescription = this.getParameter(
    //         APPPARAMETERS.productDescription
    //       );
    //       return parametersMap;
    //     })
    //   );
  }

  tableOfficers(): Observable<Officer[]> {
    return this._contextOfficers.asObservable();
  }

  countryExists(countryLabel: string): boolean {
    if (this._mapCountries.has(countryLabel.toUpperCase())) {
      return true;
    }
    return false;
  }

  getOfficers(): void {
    // return this.http
    //   .get(`${environment.apiCommonUrl}/Common/OrganizationUnitOfficers`)
    //   .pipe(
    //     map((officers: any) =>
    //       officers.data.map((officer: any) => {
    //         return Officer.deserialize(officer);
    // return new Officer(
    //   officer.id,
    //   officer.firstName,
    //   officer.lastName,
    //   officer.firstName + ' ' + officer.lastName,
    //   officer.title,
    //   officer.department,
    //   officer.phoneNumber,
    //   officer.email,
    //   officer.guid
    // );
    //     })
    //   )
    // );
    /*@bug @fuck the pipe map is incompatible with subscribe
    ,
      map((officers) => {
        this._contextOfficers.next(officers);
        return officers;
      })
      */
  }

  getFilterOfficer(): Observable<Officer> {
    return this._contextOfficer.asObservable();
  }

  getUserOfficer(): Observable<Officer> {
    return this._userOfficer.asObservable();
  }

  setFilterOfficerById(officerId: number): void {
    this.log('setFilterOfficerById ', officerId);
    this.tableOfficers().subscribe({
      next: (officers) => {
        officers.forEach((officer) => {
          if (officer.id === officerId) {
            this.log('setFilterOfficerById emit event ', officerId, officer);
            this._contextOfficer.next(officer);
            // console.log("cm.setFilterOfficerById - customerName: "+ officer.id);
          }
        });
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  getonlyOneFirstName() {
    return this.options.onlyOneFirstName;
  }

  getdisplayFirstLast() {
    return this.options.displayFirstLast;
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  //@todo isDevelopment isStaging isProduction
  isDemonstration(): boolean {
    return this._isDemonstration;
  }

  //@todo deprecated do it another way in session or security access
  isDraftAuthorize(): boolean {
    return this._isDraftAuthorize;
  }

  getCustomerName(): string {
    return this._customerName;
  }

  get customerName(): string {
    return this._customerName;
  }

  get customerId(): APPCUSTOMERSIDS {
    return this._customerId;
  }

  getLinks(): any {
    return this._links;
  }

  getLink(sLinkName: LINKTYPES): string {
    var rs = '#';
    rs = this._links.get(sLinkName) as string;
    return rs;
  }

  getParameters(): Observable<Map<APPPARAMETERS, string>> {
    return this.__parameters;
  }

  getOptions(): any {
    return this._parameters;
  }

  //@todo customername
  //      [APPPARAMETERS.customerName, lParameters.customerName],
  getParameter(parameterName: APPPARAMETERS): string {
    var rs = '#';
    switch (parameterName) {
      case APPPARAMETERS.customerName:
        rs = this._personalizations.get(LINKTYPES.customerName) as string;
        break;
      case APPPARAMETERS.title:
        rs = this._personalizations.get(LINKTYPES.title) as string;
        break;
      default:
        rs = this._parameters.get(parameterName) as string;
        break;
    }
    return rs;
  }

  consoleLogging(componentName: string): boolean {
    let rs: boolean = false;
    if (this.isDevelopment || this.isStaging) {
      switch (componentName) {
        case 'LeNomDuComposantDont la journalisation doit être activée':
        case 'DraftComponent':
        //case 'ConfigurationService':
        //case 'LifiService':
        //case 'DashboardCard002Component':
        case 'libfiles/pages/ViewComponent':
          rs = true;
          break;
        default:
          this.log('consoleLogging: unknow component ', componentName);
          break;
      }
    }
    return rs;
  }

  isParameterOn(parameterName: APPPARAMETERS): boolean {
    let rs: boolean = false;
    rs = this.getParameter(parameterName).toLowerCase() === 'on' ? true : false;
    return rs;
  }
  isParameterOff(parameterName: APPPARAMETERS): boolean {
    let rs: boolean = false;
    rs =
      this.getParameter(parameterName).toLowerCase() === 'off' ? true : false;
    return rs;
  }

  setOfficer(officer: Officer): void {
    this._contextOfficer.next(officer);
  }
  getOfficer(): Observable<Officer> {
    return this._contextOfficer;
  }

  loadParameters(): void {
    // return this.http
    //   .get<any>(`${environment.apiConfigurationUrl}/parameters`)
    //   .pipe(
    //     map((mResponseHTTP: any) => {
    //       let lParameters = mResponseHTTP.data;
    //       this._customerId = lParameters.customerId;
    //       return new Map([
    //         [
    //           APPPARAMETERS.DigitalSignatureProvider,
    //           lParameters.digitalSignatureProvider,
    //         ],
    //         [APPPARAMETERS.CommercialContact, lParameters.commercialContact],
    //         [APPPARAMETERS.customerRights, lParameters.customerRights],
    //         [APPPARAMETERS.financialMode, lParameters.financialMode],
    //         [APPPARAMETERS.civilStatusMode, lParameters.civilStatusMode],
    //         [APPPARAMETERS.stashLieFile, lParameters.stashLieFile],
    //         [APPPARAMETERS.productDescription, lParameters.productDescription],
    //         [APPPARAMETERS.registerCheckEmail, lParameters.registerCheckEmail],
    //         [APPPARAMETERS.actionReminders, lParameters.actionReminders],
    //         [APPPARAMETERS.actionActivation, lParameters.actionActivation],
    //       ]);
    //     }),
    //     map((parametersMap) => {
    //       this.__parameters.next(parametersMap);
    //       this._parameters = parametersMap;
    //       this.options.productDescription = this.getParameter(
    //         APPPARAMETERS.productDescription
    //       );
    //       return parametersMap;
    //     })
    //   );
  }

  buildProducts(products: Product[]): void {
    this._Products = products.sort((a, b) =>
      a.description < b.description ? -1 : +1
    );

    this._optGroupProducts = [];
    let _group: string = '';
    let _references: string[] = [];
    this._Products.forEach((product) => {
      this._mapProductsByName.set(product.description.toUpperCase(), product);
      this._mapProductsById.set(product.id, product);
      //because _Products has been sorted
      if (_group === '') {
        //first
        _group = product.group;
        _references = [];
      } else if (_group !== product.group) {
        //current
        this._optGroupProducts.push({
          letter: _group,
          references: _references,
        });
        //next
        _group = product.group;
        _references = [];
      }
      _references.push(product.description);
    });
    //last
    this._optGroupProducts.push({ letter: _group, references: _references });
  }

  tableProducts(): Observable<Product[]> {
    return this._obsProducts.asObservable();
  }

  getProducts(): Product[] {
    return this._Products;
  }

  getProductById(productId: number): Product {
    return this._mapProductsById.get(productId) as Product;
  }

  getOptGroupProducts(): UxuiOptGroup[] {
    return this._optGroupProducts;
  }

  productExists(reference: string): boolean {
    return this._mapProductsByName.has(reference);
  }

  getProductByReference(reference: string): Product {
    return this._mapProductsByName.get(reference) as Product;
  }
  tableRelationships(): Observable<Relationship[]> {
    return this._obsRelationships.asObservable();
  }

  getRelationships(): Relationship[] {
    return this._Relationships;
  }

  tableRelationShips(): Observable<RelationShip[]> {
    return this._obsRelationShips.asObservable();
  }

  getRelationShips(): RelationShip[] {
    return this._RelationShips;
  }

  getRelationShip(relationshipId: number): RelationShip | null {
    if (this._RelationShips) {
      const ixRelationShip = this._RelationShips.findIndex(
        (relationShip) => relationShip.id === relationshipId
      );
      if (ixRelationShip > -1) {
        return this._RelationShips[ixRelationShip];
      } else {
        return null;
      }
    } else {
      this.log(
        'loadRelationShip : fuck i dont understand and i do "un tour pour rien"'
      );
    }
    return null;
  }

  getRelationShipByKey(key: string): RelationShip | null {
    if (this._RelationShips) {
      const ixRelationShip = this._RelationShips.findIndex(
        (relationShip) => relationShip.key === key
      );
      if (ixRelationShip > -1) {
        return this._RelationShips[ixRelationShip];
      } else {
        return null;
      }
    } else {
      this.log(
        'loadRelationShip : fuck i dont understand and i do "un tour pour rien"'
      );
    }
    return null;
  }
}
