import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';

const accountModule = () =>
  import('./modules/account/account.module').then((x) => x.AccountModule);
const libfilesModule = () =>
  import('./modules/libfiles/libfiles.module').then((x) => x.LibfilesModule);
const dashboardModule = () =>
  import('./modules/dashboard/dashboard.module').then((x) => x.DashboardModule);
const homeModule = () =>
  import('./modules/home/home.module').then((x) => x.HomeModule);
const liefilesModule = () =>
  import('./modules/liefiles/liefiles.module').then((x) => x.LieFilesModule);
const cerfasModule = () =>
  import('./modules/cerfas/cerfas.module').then((x) => x.CerfasModule);

const routes: Routes = [
  { path: '', redirectTo: '/account/login', pathMatch: 'full' },
  { path: 'home', loadChildren: homeModule },
  { path: 'account', loadChildren: accountModule },
  {
    path: 'dashboard',
    loadChildren: dashboardModule,
    canActivate: [AuthGuard],
  },
  { path: 'libfiles', loadChildren: libfilesModule, canActivate: [AuthGuard] },
  { path: 'liefiles', loadChildren: liefilesModule, canActivate: [AuthGuard] },
  { path: 'cerfas', loadChildren: cerfasModule, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
