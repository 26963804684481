export enum Role {
  Beneficiary = 'BENEFICIARY', //Bénéficiaire
  Officer = 'OFFICER', //Gestionnaire
}

export enum Group {
  OfficerDraft = 'Officer', //Rôle gestionnaire, avec droits de création
  Officer = 'Officer', //Rôle gestionnaire avec droits normaux
  Procuro = 'Procuro', //Rôle gestionnaire avec droits normaux
}
