import { TaxCenterInterface } from '@app/interfaces/tax-centers.interface';

export class TaxCenter implements TaxCenterInterface {
  name: string;
  reference: string;
  hashedReference: string;
  constructor() {}
  unitName: string;
  static deserialize(props: any = {}): TaxCenter {
    let object = new TaxCenter();
    Object.assign(object, props);
    return object;
  }
}
