import { Group } from '@app/services/authentication/role.enum';
import { TaxCenter } from './tax-center.class';

export class Officer {
  // guid: string;
  public guid: string | null = null;
  token: string;
  username: string;
  role: Group;
  roleName: string;
  firstname: string;
  firstName2: string;
  firstName3: string;
  lastname: string;
  taxCenter: TaxCenter;
  password: string;
  group: Group;
  public id: number;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public fullName: string | null = null;
  public title: string | null = null;
  public department: string | null = null;
  public phoneNumber: string | null = null;
  public email: string | null = null;
  constructor() {
    this.taxCenter = new TaxCenter();
  }
  static setAllOfficer(): Officer {
    let allOfficer: Officer = new Officer();
    allOfficer.id = 0;
    allOfficer.guid = '0';
    allOfficer.firstName = 'Tous';
    allOfficer.lastName = 'Tous';
    allOfficer.fullName = 'Tous les agents';
    return allOfficer;
  }
  fullname(withDiminutiveTitle = true): string {
    let fullname = '';
    if (withDiminutiveTitle) {
      fullname += this.diminutiveTitle() + ' ';
    }
    return (fullname += this.firstName + ' ' + this.lastName?.toUpperCase());
  }
  diminutiveTitle(): string {
    switch (this.title) {
      case 'Madame':
        return 'Mme';
      case 'Mademoiselle':
        return 'Mlle';
      case 'Monsieur':
        return 'M';
    }
    return '';
  }
  static deserialize(props: any = {}): Officer {
    let officer = new Officer();
    officer = Object.assign(officer, props);
    return officer;
  }
  static deserializeLoginForm(props: any = {}): Officer {
    let officer = new Officer();
    officer.taxCenter = new TaxCenter();
    officer = Object.assign(officer, props);
    // officer.taxCenter = TaxCenter.deserialize(props.taxCenter);
    return officer;
  }
  setTaxCenterHashedReference(props: any = {}): void {
    this.taxCenter.hashedReference = props.taxCenter.hashedReference;
  }
}
